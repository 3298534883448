import { useEffect, useState, useRef } from "react";
import { SpinnerImg } from "../../loader/Loader";
import "./productList.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  FILTER_PRODUCTS,
  selectFilteredPoducts,
} from "../../../redux/features/product/filterSlice";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  deleteProduct,
  getOutOfStock,
  getProducts,
  getSales,
  sellProduct,
} from "../../../redux/features/product/productSlice";
import { addToCart, getCart } from "../../../redux/features/cart/cartSlice";
import { Tooltip } from "antd";
import { Link } from "react-router-dom";
import deleteIcon from "../../../assets/home/delete-icon.svg";
import editIcon from "../../../assets/home/edit-icon.svg";
import InventoryHeader from "../../inventoryHeader/InventoryHeader";
import { selectUser } from "../../../redux/features/auth/authSlice";
import xcrossIcon from "../../../assets/home/xcrossIcon.svg";

const ProductList = ({ products, isLoading, admin, activeRoute }) => {
  const [search, setSearch] = useState("");

  const filteredProducts = useSelector(selectFilteredPoducts);

  const dispatch = useDispatch();
  const currentUser = useSelector(selectUser);

  const shortenText = (text, n) => {
    if (text.length > n) {
      const shortenedText = text.substring(0, n).concat("...");
      return shortenedText;
    }
    return text;
  };

  const delProduct = async (id) => {
    await dispatch(deleteProduct(id));
    await dispatch(getProducts());
  };

  //   Begin Pagination
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;
  const [showProductImage, setShowProductImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(filteredProducts.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(filteredProducts.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, filteredProducts]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % filteredProducts.length;
    setItemOffset(newOffset);
  };

  const confirmDelete = (id) => {
    confirmAlert({
      title: "Delete Product",
      message: "Are you sure you want to delete this product.",
      buttons: [
        {
          label: "Delete",
          onClick: () => delProduct(id),
        },
        {
          label: "Cancel",
          // onClick: () => alert('Click No')
        },
      ],
    });
  };

  useEffect(() => {
    dispatch(FILTER_PRODUCTS({ products, search }));
  }, [products, search, dispatch]);

  const handleAddToCart = async (
    id,
    name,
    price,
    cost,
    description,
    sku,
    productIsaGroup,
    itemGroup,
    category,
    warehouse
  ) => {
    const formData = {
      name: name,
      cost: cost,
      quantity: "1",
      price: price,
      id: id,
      description: description,
      sku: sku,
      productIsaGroup: productIsaGroup ? true : false,
      itemGroup: itemGroup,
      category: category,
      warehouse: warehouse,
    };
    await dispatch(addToCart({ id, formData }));
    await dispatch(getCart());
  };

  const handleShowProductImage = (imagePath) => {
    setSelectedImage(imagePath);
    setShowProductImage(true);
  };

  const closeProductImage = () => {
    setShowProductImage(false);
    setSelectedImage(null);
  };

  const formatter = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
    }).format(amount);
  };

  return (
    <>
      <div className="product-list">
        <div className="table">
          <InventoryHeader
            search={search}
            handleSearchChange={(e) => setSearch(e.target.value)}
          />

          {isLoading && <SpinnerImg />}

          <div className="table">
            {!isLoading && products.length === 0 ? (
              <p className="no-products-p">
                -- No product found, please add a product...
              </p>
            ) : (
              <table>
                <thead>
                  <tr>
                    <th>s/n</th>
                    <th>Image</th>
                    <th>Name</th>
                    <th>Category</th>
                    <th>Warehouse</th>
                    <th>Selling Price</th>
                    <th>Quantity left</th>
                    <th>Total Value</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {currentItems.map((product, index) => {
                    const {
                      _id,
                      name,
                      category,
                      price,
                      quantity,
                      cost,
                      warehouse,
                      description,
                      sku,
                      productIsaGroup,
                      itemGroup,
                      image,
                    } = product;
                    return (
                      <tr key={_id}>
                        <td>{index + 1}</td>
                        <td>
                          {image && (
                            <>
                              <img
                                onClick={() =>
                                  handleShowProductImage(image?.filePath)
                                }
                                className="product_img"
                                src={image?.filePath}
                                alt="product image"
                              />
                              {showProductImage && (
                                <div className="show_product_image">
                                  <div
                                    className="close_btn"
                                    onClick={closeProductImage}
                                  >
                                    <img src={xcrossIcon} alt="close" />
                                  </div>
                                  <div className="product_image_preview">
                                    <img
                                      src={selectedImage}
                                      alt="enlarged product"
                                    />
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </td>
                        <td>
                          <Tooltip title={name}>
                            {shortenText(name, 16)}
                          </Tooltip>
                        </td>
                        <td>{category}</td>
                        <td>{warehouse}</td>
                        <td>{formatter(price)}</td>
                        <td>{quantity}</td>
                        <td>{formatter(price * quantity)}</td>
                        <td className="icons">
                          {admin || currentUser?.permissions?.sellProducts ? (
                            <div>
                              {Number(quantity) !== 0 ||
                              Number(quantity) > 0 ? (
                                <span>
                                  <button
                                    type="button"
                                    className="td-sell-btn"
                                    onClick={() =>
                                      handleAddToCart(
                                        _id,
                                        name,
                                        price,
                                        cost,
                                        description,
                                        sku,
                                        productIsaGroup,
                                        itemGroup,
                                        category,
                                        warehouse
                                      )
                                    }
                                  >
                                    Add
                                  </button>
                                </span>
                              ) : null}
                            </div>
                          ) : null}

                          {admin || currentUser?.permissions?.editproducts ? (
                            <span>
                              {!productIsaGroup && (
                                <Link to={`/edit-product/${_id}`}>
                                  <img src={editIcon} alt="edit" />
                                </Link>
                              )}
                            </span>
                          ) : null}

                          {admin || currentUser?.permissions?.deleteProducts ? (
                            <span>
                              {!productIsaGroup && (
                                <img
                                  onClick={() => confirmDelete(_id)}
                                  src={deleteIcon}
                                  alt="delete"
                                />
                              )}
                            </span>
                          ) : null}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="page-num"
            previousLinkClassName="page-num"
            nextLinkClassName="page-num"
            activeLinkClassName="activePageClass"
          />
        </div>
      </div>
    </>
  );
};

export default ProductList;
