import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import productService from "./productService";
import { toast } from "react-toastify";

const initialState = {
  product: null,
  sale: null,
  topProducts: [],
  lowProducts: [],
  salesByYear: [],
  products: [],
  allProductGroups: [],
  sales: [],
  productsOutOfStock: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  totalSalesValue: 0,
  totalProfitValue: 0,
  totalStoreValue: 0,
  outOfStock: 0,
  category: [],
  savingDraftStatus: "",
  draft: null,
};

// Create New Product
export const createMultipleProducts = createAsyncThunk(
  "products/createMultipleProducts",
  async (formData, thunkAPI) => {
    try {
      return await productService.createMultipleProducts(formData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create New Product
export const createProduct = createAsyncThunk(
  "products/create",
  async (formData, thunkAPI) => {
    try {
      return await productService.createProduct(formData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Sell product
export const sellProduct = createAsyncThunk(
  "product/sellProducById",
  async ({ id, formData }, thunkAPI) => {
    try {
      return await productService.sellProduct(id, formData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get all low products
export const getLowProducts = createAsyncThunk(
  "products/getAllLowSellingProducts",
  async (_, thunkAPI) => {
    try {
      return await productService.getLowProducts();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get all top selling products
export const getSalesByYear = createAsyncThunk(
  "products/getAllAllSalesByYear",
  async (id, thunkAPI) => {
    try {
      return await productService.getSalesByYear(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get all top selling products
export const getTopProducts = createAsyncThunk(
  "products/getAllTopSellingProducts",
  async (_, thunkAPI) => {
    try {
      return await productService.getTopProducts();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get all product groups
export const getAllProductGroups = createAsyncThunk(
  "products/getAllProductGroups",
  async (_, thunkAPI) => {
    try {
      return await productService.getProductGroups();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get all products
export const getProducts = createAsyncThunk(
  "products/getAll",
  async (_, thunkAPI) => {
    try {
      return await productService.getProducts();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get all products that are out of stock
export const getOutOfStock = createAsyncThunk(
  "products/getAllProductsOutOfStock",
  async (_, thunkAPI) => {
    try {
      return await productService.getOutOfStock();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get all Sales
export const getSales = createAsyncThunk(
  "products/getAllSales",
  async (interval, thunkAPI) => {
    try {
      return await productService.getSales(interval);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete a Product
export const deleteProduct = createAsyncThunk(
  "products/delete",
  async (id, thunkAPI) => {
    try {
      return await productService.deleteProduct(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete a group item
export const deleteGroupItem = createAsyncThunk(
  "products/deleteGroup",
  async (id, thunkAPI) => {
    try {
      return await productService.deleteGroup(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
)


// Get a product
export const getProduct = createAsyncThunk(
  "products/getSingleProduct",
  async (id, thunkAPI) => {
    try {
      return await productService.getProduct(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get a sale
export const getSale = createAsyncThunk(
  "products/getSaleById",
  async (id, thunkAPI) => {
    try {
      return await productService.getSale(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update product
export const updateProduct = createAsyncThunk(
  "products/updateProduct",
  async ({ id, formData }, thunkAPI) => {
    try {
      // console.log("update", id, formData);
      return await productService.updateProduct(id, formData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update product group
export const updateProductGroup = createAsyncThunk(
  "products/groupUpdate",
  async ({ id, formData }, thunkAPI) => {
    try {
      // console.log("update", id, formData);
      return await productService.updateProductGroup(id, formData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Save product to draft
export const saveDraft = createAsyncThunk(
  "products/draft/saveDraft",
  async (formData, thunkAPI) => {
    try {
      return await productService.saveDraft(formData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Save product to draft
export const getDraft = createAsyncThunk(
  "products/draft/getDraft",
  async (_, thunkAPI) => {
    try {
      return await productService.getDraft();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    CALC_STORE_VALUE(state, action) {
      const products = action.payload;
      const array = [];
      products.map((item) => {
        const { price, quantity } = item;
        const productValue = price * quantity;
        return array.push(productValue);
      });
      const totalValue = array.reduce((a, b) => {
        return a + b;
      }, 0);
      state.totalStoreValue = totalValue;
    },
    CALC_SOLD_VALUE(state, action) {
      const sales = action.payload;
      // console.log("store value",sales);
      const array = [];
      sales.map((item) => {
        const { price, quantity, } = item;
        if (price) {
          const productValue = price * quantity;
          return array.push(productValue);
        }
      });
      const totalValue = array.reduce((a, b) => {
        return a + b;
      }, 0);
      state.totalSalesValue = totalValue;
    },
    CALC_PROFIT_VALUE(state, action) {
      const sales = action.payload;
      const array = [];
      sales.map((item) => {
        const { cost, quantity, price } = item;
        if (price && cost) {
          const profit = (price * quantity) - (cost * quantity);
          return array.push(profit);
        }
      });
      const totalValue = array.reduce((a, b) => {
        return a + b;
      }, 0);
      state.totalProfitValue = totalValue;
    },
    CALC_OUTOFSTOCK(state, action) {
      const products = action.payload;
      const array = [];
      products.map((item) => {
        const { quantity } = item;

        return array.push(quantity);
      });
      let count = 0;
      array.forEach((number) => {
        if (number === 0 || number === "0") {
          count += 1;
        }
      });
      state.outOfStock = count;
    },
    CALC_CATEGORY(state, action) {
      const products = action.payload;
      const array = [];
      products.map((item) => {
        const { category } = item;

        return array.push(category);
      });
      const uniqueCategory = [...new Set(array)];
      state.category = uniqueCategory;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createMultipleProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createMultipleProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        // console.log(action.payload);
        state.allProductGroups.push(action.payload.data);
        toast.dismiss();
        toast.success("Product Group Created ...");
      })
      .addCase(createMultipleProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.dismiss();
        toast.error(action.payload);
      })
      .addCase(createProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        // console.log(action.payload);
        state.products.push(action.payload);
        toast.dismiss();
        toast.success("Product added successfully");
      })
      .addCase(createProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.dismiss();
        toast.error(action.payload);
      })
      .addCase(sellProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sellProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        toast.dismiss();
        toast.success("Product sold out!");
      })
      .addCase(sellProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.dismiss();
        toast.error(action.payload);
      })
      .addCase(getAllProductGroups.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllProductGroups.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        // console.log(action.payload);
        state.allProductGroups = action.payload;
      })
      .addCase(getAllProductGroups.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.dismiss();
        toast.error(action.payload);
      })
      .addCase(getProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        // console.log(action.payload);
        state.products = action.payload;
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.dismiss();
        toast.error(action.payload);
      })
      .addCase(getSalesByYear.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSalesByYear.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.salesByYear = action.payload;
      })
      .addCase(getSalesByYear.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.dismiss();
        toast.error(action.payload);
      })
      .addCase(getLowProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLowProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.lowProducts = action.payload;
      })
      .addCase(getLowProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.dismiss();
        toast.error(action.payload);
      })
      .addCase(getTopProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTopProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.topProducts = action.payload;
      })
      .addCase(getTopProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.dismiss();
        toast.error(action.payload);
      })
      .addCase(getOutOfStock.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOutOfStock.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        // console.log(action.payload);
        state.productsOutOfStock = action.payload;
      })
      .addCase(getOutOfStock.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.dismiss();
        toast.error(action.payload);
      })
      .addCase(getSales.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSales.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        // console.log(action.payload);
        state.sales = action.payload;
      })
      .addCase(getSales.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.dismiss();
        toast.error(action.payload);
      })
      .addCase(deleteGroupItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteGroupItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        toast.dismiss();
        toast.success("Product Group Deleted");
      })
      .addCase(deleteGroupItem.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.dismiss();
        toast.error(action.payload);
      })
      .addCase(deleteProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        toast.dismiss();
        toast.success("Product deleted successfully");
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.dismiss();
        toast.error(action.payload);
      })
      .addCase(getProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.product = action.payload;
      })
      .addCase(getProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.dismiss();
        toast.error(action.payload);
      })
      .addCase(getSale.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSale.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.sale = action.payload;
      })
      .addCase(getSale.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.dismiss();
        toast.error(action.payload);
      })
      .addCase(updateProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        toast.dismiss();
        toast.success("Product updated successfully");
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.dismiss();
        toast.error(action.payload);
      })
      .addCase(updateProductGroup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProductGroup.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        toast.dismiss();
        toast.success("Product updated successfully");
      })
      .addCase(updateProductGroup.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.dismiss();
        toast.error(action.payload);
      })
      .addCase(saveDraft.pending, (state) => {
        state.savingDraftStatus = "saving draft";
      })
      .addCase(saveDraft.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.isError = false;
        state.savingDraftStatus = "saved draft";
        state.draft = action.payload;
      })
      .addCase(saveDraft.rejected, (state, action) => {
        state.isError = true;
        state.message = action.payload;
        state.savingDraftStatus = "error saving draft";
      })
      .addCase(getDraft.pending, (state) => {
        state.message = "pending";
      })
      .addCase(getDraft.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.isError = false;
        state.draft = action.payload;
      })
      .addCase(getDraft.rejected, (state, action) => {  
        state.isError = true;
        state.message = action.payload;
        state.draft = null;
      });
  },
});

export const { CALC_STORE_VALUE, CALC_OUTOFSTOCK, CALC_CATEGORY, CALC_PROFIT_VALUE, CALC_SOLD_VALUE } =
  productSlice.actions;

export const selectIsLoading = (state) => state.product.isLoading;
export const selectProduct = (state) => state.product.product;
export const selectTotalStoreValue = (state) => state.product.totalStoreValue;
export const selectOutOfStock = (state) => state.product.outOfStock;
export const selectCategory = (state) => state.product.category;
export const selectTotalSales = (state) => state.product.totalSalesValue;
export const selectTotalProfit = (state) => state.product.totalProfitValue;
export const selectDraft = (state) => state.product.draft;
export const selectSavedStatus = (state) => state.product.savingDraftStatus

export default productSlice.reducer;
